exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogi-index-js": () => import("./../../../src/pages/blogi/index.js" /* webpackChunkName: "component---src-pages-blogi-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kayttoehdot-index-js": () => import("./../../../src/pages/kayttoehdot/index.js" /* webpackChunkName: "component---src-pages-kayttoehdot-index-js" */),
  "component---src-pages-kiitos-index-js": () => import("./../../../src/pages/kiitos/index.js" /* webpackChunkName: "component---src-pages-kiitos-index-js" */),
  "component---src-pages-koulutukset-asiakaspalvelukoulutus-index-js": () => import("./../../../src/pages/koulutukset/asiakaspalvelukoulutus/index.js" /* webpackChunkName: "component---src-pages-koulutukset-asiakaspalvelukoulutus-index-js" */),
  "component---src-pages-koulutukset-esiintymiskoulutus-index-js": () => import("./../../../src/pages/koulutukset/esiintymiskoulutus/index.js" /* webpackChunkName: "component---src-pages-koulutukset-esiintymiskoulutus-index-js" */),
  "component---src-pages-koulutukset-index-js": () => import("./../../../src/pages/koulutukset/index.js" /* webpackChunkName: "component---src-pages-koulutukset-index-js" */),
  "component---src-pages-koulutukset-johdon-ja-esimiesten-koulutus-index-js": () => import("./../../../src/pages/koulutukset/johdon-ja-esimiesten-koulutus/index.js" /* webpackChunkName: "component---src-pages-koulutukset-johdon-ja-esimiesten-koulutus-index-js" */),
  "component---src-pages-koulutukset-tyoyhteiso-ja-tiimikoulutus-index-js": () => import("./../../../src/pages/koulutukset/tyoyhteiso-ja-tiimikoulutus/index.js" /* webpackChunkName: "component---src-pages-koulutukset-tyoyhteiso-ja-tiimikoulutus-index-js" */),
  "component---src-pages-meista-index-js": () => import("./../../../src/pages/meista/index.js" /* webpackChunkName: "component---src-pages-meista-index-js" */),
  "component---src-pages-otayhteytta-index-js": () => import("./../../../src/pages/otayhteytta/index.js" /* webpackChunkName: "component---src-pages-otayhteytta-index-js" */),
  "component---src-pages-podcast-index-js": () => import("./../../../src/pages/podcast/index.js" /* webpackChunkName: "component---src-pages-podcast-index-js" */),
  "component---src-pages-pyyda-tarjous-index-js": () => import("./../../../src/pages/pyyda-tarjous/index.js" /* webpackChunkName: "component---src-pages-pyyda-tarjous-index-js" */),
  "component---src-pages-tietosuojaseloste-index-js": () => import("./../../../src/pages/tietosuojaseloste/index.js" /* webpackChunkName: "component---src-pages-tietosuojaseloste-index-js" */),
  "component---src-pages-verkkokurssit-index-js": () => import("./../../../src/pages/verkkokurssit/index.js" /* webpackChunkName: "component---src-pages-verkkokurssit-index-js" */)
}

